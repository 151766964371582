<template>
	<div class="loginForm_box">
		<div class="box1">
			<div class="typeinfo">
				<div class="typeobj" @click="handlelogintype(0)" :class="{'active':loginform.typeindex==0}">用户名登录</div>
				<div class="typeobj" @click="handlelogintype(1)" :class="{'active':loginform.typeindex==1}">手机验证码登录</div>
			</div>
			<div class="block10"></div>
			<div class="inputbox" v-if="loginform.typeindex==0">
				<el-input
				    placeholder="请输入用户名"
				    prefix-icon="el-icon-user"
				    v-model="loginform.username">
				  </el-input>
			</div>
			<div class="inputbox" v-if="loginform.typeindex==0">
				<el-input
				    placeholder="请输入密码"
				    prefix-icon="el-icon-lock"
					type="password"
				    v-model="loginform.password">
				  </el-input>
			</div>
			<div class="inputbox" v-if="loginform.typeindex==1">
				<el-input
				    type="number"
				    placeholder="请输入手机号"
				    prefix-icon="el-icon-phone"
				    v-model="loginform.phone">
				</el-input>
			</div>
			<div class="inputbox" v-if="loginform.typeindex==1">
				<div class="flexrow">
					<el-input type="number" prefix-icon="el-icon-news" v-model="loginform.captcha" placeholder="请输入验证码"></el-input>
					<div class="captchabtn fontsize12" @click="setcaptcha" :disabled="getCodeisWaiting">{{captchatext}}</div>
				</div>
			</div>
			<div class="block10"></div>
			<div class="checkbox"><el-checkbox v-model="loginform.autologin"></el-checkbox><span class="ml">自动登录</span></div>
			<div class="checkbox"><el-checkbox v-model="loginform.isgreen"></el-checkbox><span class="ml">选择同意</span><span class="zhuse pointer" @click="handleSearche('agreement')">《{{bsnName}}用户协议》</span></div>
			<div class="loginbtn" @click="handleLogin" v-if="!loginWaiting">登录</div>
			<div class="loginbtn" v-if="loginWaiting">登录中...</div>
			<div class="forgetbox">
				<div class="text" @click="handlezhuce('zhuce')">没有账号？去注册</div>
				<div class="text" @click="handlezhuce('password')">忘记密码</div>
			</div>
		</div>
		<!-- 注册弹窗 -->
		<registerForm :isShow="zhuceshow" :istype="istype" @handlezhuceiForm="handlezhuceiForm" titlename="注册"></registerForm>
		<!-- 注册弹窗 -->
		<registerForm :isShow="getpasswordshow" :istype="istype" @handlezhuceiForm="handlezhuceiForm" titlename="忘记密码"></registerForm>
	</div>
</template>
<script>
	import {mapState,mapMutations} from 'vuex'
	import {
		showLoading,
		hideLoading
	} from '@/common/loading.js';
	import  registerForm  from "../components/registerForm/registerForm.vue";
	export default {
		components: {
			registerForm
		},
		computed: {
			...mapState(['isLogin','bsnName','userInfo']),
		},
		data() {
			return {
				rules: {
					afterstar: [{
						required: true,
						message: '此项为必填项',
						trigger: 'blur'
					}]
				},
				loginform:{
					typeindex:0,
					username:"",
					password:"",
					captcha:'',
					phone:"",
					autologin:true,
					isgreen:true,
				},
				captchatext:"发送验证码",
				loginWaiting: false, // 登录禁止多次点击
				getCodeisWaiting: false, // 禁止多次点击
				istype:"add",//注册还是找回密码
				zhuceshow:false,//注册
				getpasswordshow:false,//找回密码
			};
		},
		created() {
			if(this.isLogin){
				this.$router.replace("/")
			}
		},
		methods: {
			...mapMutations(["SET_USERINFO",'SET_ISLOGIN']),
			//调整
			handleSearche(name){
				this.$router.push({
					name,
				});
			},
			//注册
			handlezhuce(type){
				if(type == 'zhuce'){
					this.istype = 'add'
					this.zhuceshow  = true
				}else{
					this.istype = 'password'
					this.getpasswordshow = true
				}
			},
			//打开注册认证
			handlezhuceiForm(type){
				if(this.istype == 'add'){
					if(type=="open"){
						this.zhuceshow = true
					}else{
						this.zhuceshow = false
					}
				}else{
					if(type=="open"){
						this.getpasswordshow = true
					}else{
						this.getpasswordshow = false
					}
				}
			},
			//登录
			handleLogin(){
				var _this = this
				if(!this.loginform.isgreen){
					this.$message.error("请先勾选同意用户协议")
					return false;
				}
				var params = {}
				var control = 'app/hlexam/login/login'
				if(this.loginform.typeindex==0){
					if(!this.loginform.username){
						this.$message.error("请先输入用户名")
						return false;
					}
					if(!this.loginform.password){
						this.$message.error("请先输入密码")
						return false;
					}
					params["password"] = this.loginform.password
					params["username"] = this.loginform.username
				}else{
					if(!this.loginform.phone){
						this.$message.error("请先输入手机号")
						return false;
					}
					if(!this.loginform.captcha){
						this.$message.error("请先输入验证码")
						return false;
					}
					params["phone"] = this.loginform.phone
					params["captcha"] = this.loginform.captcha
					control = 'app/hlexam/login/loginPhone'
				}
				this.loginWaiting = true //禁止点击
				showLoading()
				this.$http.post(control, params).then(function(res) {
					hideLoading()
					_this.loginWaiting = false //禁止点击
					//数据处理
					if (res.code == 200) {
						_this.$message.success("登录成功")
						sessionStorage.setItem('token',res.data.token);
						_this.SET_ISLOGIN(true)
						_this.SET_USERINFO(res.data)
						//保存
						sessionStorage.setItem('key_state',JSON.stringify(_this.$store.state))
						_this.$router.replace("/")
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//登录类型
			handlelogintype(type){
				this.loginform.typeindex = type
			},
			//发验证码
			setcaptcha(){
				var _this = this
				if(!this.loginform.phone){
					this.$message.error("请输入手机号")
					return false;
				}
				var params = {}
				params["phone"] = this.loginform.phone
				this.getCodeisWaiting = true //禁止点击
				showLoading()
				this.$http.post('app/hlexam/login/captchaPhone', params).then(function(res) {
					hideLoading()
					//数据处理
					if (res.code == 200) {
						_this.$message.success("验证码发送成功")
						//倒计时
						_this.setTimer()
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			},
			//倒计时
			setTimer() {
			    var holdTime = 60;
			    this.getCodeisWaiting = true;
			    var Timer = setInterval(() => {
			    if (holdTime <= 0) {
			        this.getCodeisWaiting = false;
			        this.captchatext = "重新获取";
			        clearInterval(Timer);
			        return;
			    }
			    this.captchatext = holdTime + "s可重发";
			    holdTime--;
			    }, 1000);
			},
			//关闭登录弹窗的回调
			closeLoginform() {
				this.$emit("handlezhuceiForm", 'close')
			},
		}
	};
</script>
<style lang="scss" scoped>
.loginForm_box{
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	.box1{
		width: 438px;
		height: 362px;
		border-radius: 12px;
		background: #FFF;
		box-shadow: 0px 9px 9.3px 0px rgba(55, 83, 236, 0.10);
		padding: 24px;
		box-sizing: border-box;
		border: 1px solid #EF870C;
		.typeinfo{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.typeobj{
				width: 188px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				border-radius: 4px;
				border: 1px solid #EF870C;
				box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.13);
				color: #EF870C;
				cursor: pointer;
				&.active{
					background: #EF870C;
					color: #ffffff;
				}
			}
		}
		.inputbox{
			margin-top: 10px;
		}
		.captchabtn{
			margin-left: 10px;
			width: 112px;
			height: 38px;
			line-height: 38px;
			background: #EF870C;
			color: #ffffff;
			text-align: center;
			border-radius: 4px;
			border: 1px solid #EF870C;
			box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.13);
			cursor: pointer;
		}
		.checkbox{
			.ml{
				margin-left: 5px;
			}
		}
		.loginbtn{
			margin-top: 10px;
			width: 100%;
			height: 48px;
			line-height: 48px;
			background: #EF870C;
			color: #ffffff;
			text-align: center;
			border-radius: 8px;
			border: 1px solid #EF870C;
			box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.13);
			cursor: pointer;
		}
		.forgetbox{
			margin-top: 15px;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.text{
				color: #EF870C;
				cursor: pointer;
			}
		}
	}
}
</style>